.call-analysis-main {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.analysis-tabs {
  margin: 10px;
}

.play-call-box {
  margin: 10px;
  margin-left: 0px;
}

.transcript-box {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  height: 400px;
  overflow-y: scroll;
}

.delete-button-div {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}

.call-analysis-layout {
  min-height: 100vh;
  background: #f0f2f5;
}

.call-analysis-content {
  padding: 24px;
  max-width: 1800px;
  margin: 0 auto;
}

.stats-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.stat-item {
  flex: 1;
  padding: 12px;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.stat-item:last-child {
  border-bottom: none;
}

/* Make statistics more compact */
.stat-item .ant-statistic-title {
  margin-bottom: 4px;
  font-size: 14px;
}

.stat-item .ant-statistic-content {
  font-size: 20px;
}

/* .transcript-inner {
  height: calc(100% - 55px);
  display: flex;
  flex-direction: column;
} */

.audio-player {
  flex: 0 0 auto;
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 8px;
}

.metrics-card {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.metrics-card .ant-card-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
}

.metrics-card .ant-row {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.metrics-card .ant-col {
  margin-bottom: 16px;
}

.metrics-card .ant-col:last-child {
  margin-bottom: 0;
}

.transcript-container {
  flex: 1;
  overflow-y: auto;
  padding: 8px;
  height: 400px;
  overflow-y: scroll;
  background-color: #fafafa;
}

/* Transcript Message Styles */
.transcript-line {
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 4px;
  background: white;
}

.transcript-line:last-child {
  margin-bottom: 0;
}

.transcript-line.agent {
  margin-right: 16px;
  border-left: 3px solid #1890ff;
}

.transcript-line.customer {
  margin-left: 16px;
  border-left: 3px solid #52c41a;
}

.transcript-header {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.transcript-timestamp {
  margin-left: 8px;
  color: #8c8c8c;
  font-size: 12px;
}

.transcript-text {
  line-height: 1.4;
  font-size: 14px;
  word-break: break-word;
}

/* Analysis Card Styles */
.analysis-card {
  margin-top: 24px;
}

/* Scrollbar Styles */
.transcript-container::-webkit-scrollbar {
  width: 4px;
}

.transcript-container::-webkit-scrollbar-track {
  background: #ffffff;
}

.transcript-container::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 2px;
}

.transcript-line {
  margin-bottom: 16px;
  padding: 12px 16px;
  border-radius: 8px;
  background: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.transcript-header {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.transcript-timestamp {
  margin-left: 8px;
  color: #8c8c8c;
  font-size: 12px;
  font-family: monospace;
  background: #f5f5f5;
  padding: 2px 6px;
  border-radius: 4px;
}

.transcript-text {
  line-height: 1.5;
  word-break: break-word;
}

.transcript-line:hover {
  background-color: #f8f8f8;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.transcript-line.agent {
  margin-right: 32px;
  border-left: 4px solid #1890ff;
}

.transcript-line.customer {
  margin-left: 32px;
  border-left: 4px solid #52c41a;
}

.ant-statistic-title {
  font-size: 14px;
  color: #8c8c8c;
}

.ant-statistic-content {
  font-size: 20px;
  color: #262626;
}

/* Make tabs more compact in analysis section */
.analysis-card .ant-tabs-nav {
  margin-bottom: 12px;
}

.analysis-card .ant-table {
  font-size: 13px;
}

/* Add some hover effect to the stat cards */
.ant-card:hover {
  box-shadow:
    0 1px 2px -2px rgba(0, 0, 0, 0.16),
    0 3px 6px 0 rgba(0, 0, 0, 0.12),
    0 5px 12px 4px rgba(0, 0, 0, 0.09);
  transition: all 0.3s;
}

.active-snippet {
  background-color: rgba(24, 144, 255, 0.1);
  border-left: 3px solid #1890ff;
}

.important-keywords-container {
  margin-top: 16px;
}

.important-keywords-container .ant-table-small .ant-table-cell {
  padding: 8px 16px;
}

.important-keywords-container .ant-tabs-nav {
  margin-bottom: 16px;
}

.important-keywords-container .ant-table {
  background: white;
  border-radius: 8px;
}

.important-keywords-container .ant-table-thead > tr > th {
  background: #fafafa;
}
